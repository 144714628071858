<template>
  <base-list-view
    header-hidden
    hide-default-footer
    :show-add-in-table="true"
    show-actions
    editable
    isNested
    :dataSource="value"
    :headers="headers"
    @customHandle="customHandle"
    @customAdd="add"
    @customEdit="edit"
    notShowSelect
    notShowSettings
  >
    <template slot="item.Text" slot-scope="scope">
      <div v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id">
        <v-text-field
          v-model="scope.rowEditing.Text"
          @click.stop
          label="Название"
          maxlength="200"
        ></v-text-field>
      </div>
      <span v-else>
        {{ scope.index + 1 }}. {{ scope.item.Text }}</span
      ></template
    >
    <testing-questions-add
      v-model="dialog"
      @save="addNew"
      :question="itemEditeng"
      :questionCount="value.length"
    />
  </base-list-view>
</template>
<script>
import BaseListView from "@/layouts/BaseListView.vue";
import testingQuestionsAdd from "./TestingQuestionsAdd.vue";

export default {
  components: {
    BaseListView,
    testingQuestionsAdd,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Вопросы",
          value: "Text",
        },
      ],
      dialog: false,
      itemEditeng: null,
    };
  },
  methods: {
    edit(item) {
      this.itemEditeng = { ...item };
      this.dialog = true;
    },
    addNew(item) {
      const itemIndex = this.value.findIndex((e) => e.Id === item.Id);
      if (itemIndex >= 0) {
        this.$set(this.value, itemIndex, item);
      } else this.value.push(item);
    },
    add() {
      this.itemEditeng = null;
      this.dialog = true;
    },
    customHandle(item) {
      this.itemEditeng = { ...item };
      this.dialog = true;
    },
  },
};
</script>
